jQuery(document).ready(function ($) {
	responsive.init();
	// Polyfill SVGs for older browsers
	svg4everybody();
	// Home featured box slider
	if ($('.section-featured-boxes ul.list-image li').length) {

		var slide = $('.section-featured-boxes ul.list-image');

		slide.slick({
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			dots: true,
			arrows: false,
		});

	}

	// Testimonial slider
	if ($('.section-testimonials ul.list-testimonial li').length) {

		var slide = $('.section-testimonials ul.list-testimonial');

		slide.slick({
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			dots: true,
			arrows: false,
		});

	}

	// Testimonial slider
	if ($('#blog-post .section-hero ul.list-image li').length) {

		var slide = $('#blog-post .section-hero ul.list-image');

		slide.slick({
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			dots: true,
			arrows: false,
		});

	}

	// Collection Product Slider
	if ($('#collection-product ul.list-image li, #accessories-product ul.list-image li').length) {
		var slide = $('ul.list-image');

		slide.slick({
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000,
			dots: true,
			arrows: false,
		});

	}

	// Footer subscribe form processing
	$('form#form-footer-subscribe').on('submit', function (e) {
		e.preventDefault();
		$.ajax({
			method: 'POST',
			url: 'process/subscribe/',
			data: $(this).serialize()
		}).done(function (response) {
			if (response == 'success') {
				// Close form
				$('form#form-footer-subscribe').hide();
				// Close form errors (if any)
				$('.footer-subscribe .sub-errors').hide();
				// show success message
				$('.footer-subscribe .sub-success').show();
			}
			else {
				// Display errors
				$('.footer-subscribe .sub-errors').html(response);
			}
		});
	});

	// Pops out share modal window
	$(".social-share").click(function (e) {
		e.preventDefault();
		windowPopup($(this).attr("href"), 500, 300);
	});

	// TODO: replace access token with correct token
	$('.section-instafeed .list-feed').instagramLite({
		accessToken: '8687721802.454d73f.f6bca3431ce74751b38879d28e9ce5d8',
		limit: 4,
		urls: true,
		success: function () {
			$('.list-feed li.last').appendTo($('.section-instafeed .list-feed'));
		}
	});

	//// MOBILE
	$('.header-main .btn-mobile').click(function (e) {
		$(this).toggleClass('active');
		$('.header-main ul.list-nav').toggleClass('active');
	});

	// Ensure the function exists on the page before we call it.
	if (!!window['gdprSubscribeModal']) {
		// The form of which to attach the modal to.
		var formSelector = '#form-footer-subscribe';
		// The url to link to for the privacy policy.
		var privacyPolicyURL = 'privacy-policy.html';
		// Color of the links in the modal
		var linkColour = '#EC6D8E';
		// Color of the buttons in the modal.
		var buttonTextColour = '#fff';

		// Call this per form.
		gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
	}
});


// This automatically adds 'data-sitekey' to the buttons.
// and programmically binds a callback for captcha completion.
// add '.captcha-ignore' as a class on the button to ignore
// the button and disable captcha on that form.
var CaptchaCallback = function () {
	var SITE_KEY = '6LeHIJAUAAAAABHbpmgtGiH2ZisX6tYszXr1fW4m';

	// Don't execute if no site key present.
	if (SITE_KEY === '') {
		return;
	}

	// Get all form elements on page.
	var pageForms = $('form');

	// Ensure the page has a form on it, at least.
	if (pageForms.length <= 0) {
		return;
	}


	pageForms.each(function (formIncId, form) {
		// Grab all of the 'submit' buttons which are attached to the form.
		var submissionButtons = $(form).find('input[type="submit"]:not(.captcha-ignore)');

		if (submissionButtons.length <= 0) {
			return; // Return here is similar to 'continue' within a loop.
		}

		submissionButtons.each(function (btnIncId, button) {
			$(button).attr('data-sitekey', SITE_KEY);

			grecaptcha.render(button, {
				sitekey: SITE_KEY,
				callback: function () {
					$(button).closest('form').submit();
				}
			});
		});
	});
};

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position of the popup so
	// it’s centered on the screen.
	var left = (screen.width / 2) - (width / 2),
		top = (screen.height / 2) - (height / 2);

	window.open(
		url,
		"",
		"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
	);
}